export default [
  {
    header: 'All-State',
    action: 'read',
    subject: 'all-state',
  },
  {
    title: 'Applications',
    route: 'all-state-applications',
    icon: 'ClipboardIcon',
    fa: ['far', 'clipboard'],
    action: 'read',
    subject: 'all-state-applications',
  },
  {
    title: 'Students',
    route: 'all-state-students',
    icon: 'UserIcon',
    fa: ['fas', 'graduation-cap'],
    action: 'read',
    subject: 'all-state-students',
  },
  {
    title: 'Scores',
    route: 'all-state-scores',
    icon: 'HashIcon',
    //fa: ['fas', 'sort-numeric-down'],
    action: 'read',
    subject: 'all-state-scores',
  },
  {
    title: 'Selections',
    route: 'all-state-selections',
    icon: 'PlusSquareIcon',
    action: 'read',
    subject: 'all-state-selections',
  },
  {
    title: 'Acceptance',
    route: 'all-state-acceptance',
    icon: 'CheckSquareIcon',
    action: 'read',
    subject: 'all-state-acceptance',
  },

  /** Events **/
  {
    header: 'Events',
    action: 'read',
    subject: 'events',
  },
  /*{
    title: 'Badges',
    icon: 'PrinterIcon',
    fa: ['far', 'id-badge'],
    children: [
      {
        title: 'Conference',
        fa: ['far', 'id-card'],
        route: { name: 'events-badge', params: { id: 'conference' }},
        //route: 'events-badges',
        action: 'read',
        subject: 'events-badges',
      },
      {
        title: 'Albany',
        fa: ['far', 'id-card'],
        //route: 'events-badges',
        route: { name: 'events-badge', params: { id: 'albany' }},
        action: 'read',
        subject: 'events-badges',
      },
    ],
  },*/
  {
    title: 'Badges',
    icon: 'PrinterIcon',
    fa: ['far', 'id-card'],
    tag: 'rc',
    tagVariant: 'success',
    route: 'events-badges',
    action: 'read',
    subject: 'events-badges',
  },
  {
    title: 'Buses',
    icon: 'CalendarIcon',
    fa: ['fas', 'bus'],
    tag: 'rc',
    tagVariant: 'success',
    route: 'events-buses',
    action: 'read',
    subject: 'events-buses',
  },
  {
    title: 'Chaperones',
    icon: 'CalendarIcon',
    fa: ['fas', 'people-roof'],
    tag: 'rc',
    tagVariant: 'success',
    route: 'events-chaperones',
    action: 'read',
    //subject: 'events-chaperones',
    subject: 'events-chaperones',
  },
  {
    title: 'Hotels',
    icon: 'CalendarIcon',
    fa: ['fas', 'hotel'],
    tag: 'rc',
    tagVariant: 'success',
    route: 'events-hotels',
    action: 'read',
    subject: 'events-hotels',
  },
  {
    title: 'Room Assignment',
    icon: 'CalendarIcon',
    fa: ['fas', 'bed'],
    tag: 'rc',
    tagVariant: 'success',
    route: 'events-room-assignment',
    action: 'read',
    subject: 'events-room-assignment',
  },
  {
    title: 'Students',
    icon: 'CalendarIcon',
    fa: ['fas', 'graduation-cap'],
    tag: 'rc',
    tagVariant: 'success',
    route: 'events-students',
    action: 'read',
    //subject: 'events-students',
    subject: 'events-students',
  },

  /** Reports **/
  {
    header: 'Reports',
    action: 'read',
    subject: 'reports',
  },
  {
    title: 'Recommendation',
    icon: 'InfoIcon',
    route: 'reports-application-recommendation',
    action: 'read',
    subject: 'reports-application-recommendation',
  },
  {
    title: 'State Ranking',
    icon: 'InfoIcon',
    route: 'reports-state-ranking',
    action: 'read',
    subject: 'reports-state-ranking',
  },
  {
    title: 'Part Assignment',
    icon: 'InfoIcon',
    route: 'reports-part-assignment',
    action: 'read',
    subject: 'reports-part-assignment',
  },
  {
    title: 'Ensemble Roster',
    icon: 'InfoIcon',
    route: 'reports-ensemble-roster',
    action: 'read',
    subject: 'reports-ensemble-roster',
  },
  {
    title: 'Events Roster',
    icon: 'InfoIcon',
    route: 'reports-events-roster',
    action: 'read',
    subject: 'reports-events-roster',
  },
/*  {
    title: 'Bus Roster',
    icon: 'InfoIcon',
    route: 'reports-bus-roster',
    action: 'read',
    subject: 'reports-bus-roster',
  },*/
  {
    title: 'Festival Applications',
    icon: 'InfoIcon',
    route: 'reports-festival-applications',
    action: 'read',
    subject: 'reports-festival-applications',
  },
  {
    title: 'Multiple Acceptance',
    icon: 'InfoIcon',
    route: 'reports-multiple-selection-acceptance',
    action: 'read',
    subject: 'reports-multiple-selection-acceptance',
  },
  {
    title: 'Multiple Selections',
    icon: 'InfoIcon',
    route: 'reports-multiple-selections',
    action: 'read',
    subject: 'reports-multiple-selection-acceptance',
  },

  /** Printable **/
  {
    header: 'Printable',
    action: 'read',
    subject: 'printable',
  },
  {
    title: 'Certificates',
    fa: ['fas', 'certificate'],
    route: 'printable-certificates',
    action: 'read',
    subject: 'printable-certificates',
  },
  {
    title: 'Letters',
    fa: ['far', 'envelope'],
    route: 'printable-letters',
    action: 'read',
    subject: 'printable-letters',
    children: [
      {
        title: 'Teacher Letters',
        fa: ['fas', 'envelope-open-text'],
        route: 'printable-letters-teacher',
        action: 'read',
        subject: 'printable-letters',
      },
      {
        title: 'Student Letters',
        fa: ['fas', 'envelope-open-text'],
        route: 'printable-letters-student',
        action: 'read',
        subject: 'printable-letters',
      },
      {
        title: 'School Letters',
        fa: ['fas', 'envelope-open-text'],
        route: 'printable-letters-school',
        action: 'read',
        subject: 'printable-letters',
      },
    ],
  },
  {
    title: 'Mailing Labels',
    fa: ['far', 'address-card'],
    children: [
      {
        title: 'Student Labels',
        fa: ['far', 'address-card'],
        route: 'printable-labels-students',
        action: 'read',
        subject: 'printable-labels',
      },
      {
        title: 'School Labels',
        fa: ['far', 'address-card'],
        route: 'printable-labels-schools',
        action: 'read',
        subject: 'printable-labels',
      },
    ],
  },
  {
    title: 'Program Data',
    fa: ['far', 'newspaper'],
    route: 'printable-magazine-and-program',
    action: 'read',
    subject: 'printable-magazine-and-program',
  },


  /** Management **/
  {
    header: 'Management',
    action: 'read',
    subject: 'management',
  },
  {
    title: 'Billing',
    route: 'management-billing',
    fa: ['fas', 'money-check'],
    children: [
      {
        title: 'Invoices',
        fa: ['fas', 'file-invoice-dollar'],
        route: 'management-invoices',
        action: 'read',
        subject: 'management-invoices',
      },
    ],
  },
  {
    title: 'Zones',
    icon: 'MapIcon',
    fa: ['far', 'map'],
    route: 'management-zones',
    action: 'read',
    subject: 'management-zones',
  },
  {
    title: 'Festivals',
    icon: 'HeadphonesIcon',
    route: 'management-festivals',
    action: 'read',
    subject: 'management-festivals',
  },
  {
    title: 'Forms',
    icon: 'LayoutIcon',
    route: 'management-forms',
    action: 'read',
    subject: 'management-forms',
  },
  {
    title: 'Instruments',
    icon: 'MusicIcon',
    route: 'management-instruments',
    action: 'read',
    subject: 'management-instruments',
  },
  {
    title: 'Ensembles',
    icon: 'SlidersIcon',
    fa: ['fas', 'people-line'],
    route: 'management-ensembles',
    action: 'read',
    subject: 'management-ensembles',
  },
  {
    title: 'Districts',
    route: 'management-districts',
    icon: 'UmbrellaIcon',
    fa: ['fas', 'building-columns'],
    action: 'read',
    subject: 'management-districts',
  },
  {
    title: 'Schools',
    icon: 'DropletIcon',
    fa: ['fas', 'school-flag'],
    route: 'management-schools',
    action: 'read',
    subject: 'management-schools',

  },
  {
    title: 'Users',
    icon: 'UsersIcon',
    //fa: ['fas', 'user-group'],
    route: 'management-users',
    action: 'read',
    subject: 'management-users',

  },

  {
    title: 'Settings',
    icon: 'SettingsIcon',
    action: 'read',
    subject: 'management-settings',
    children: [
      {
        title: 'App',
        route: 'management-settings-app',
        action: 'read',
        subject: 'management-settings-app',
      },
      {
        title: 'Applications',
        route: 'management-settings-application',
        action: 'read',
        subject: 'management-settings-application',
      },
      {
        title: 'Selections',
        route: 'management-settings-selection',
        action: 'read',
        subject: 'management-settings-selection',
      },
      {
        title: 'Billing',
        route: 'management-settings-billing',
        action: 'read',
        subject: 'management-settings-billing',
      },
      {
        title: 'Support',
        route: 'management-settings-support',
        action: 'read',
        subject: 'management-settings-support',
      },
    ],
  },

  {
    header: 'Developer',
    action: 'read',
    subject: 'dev',
  },
  {
    title: 'Import',
    fa: ['fas', 'file-import'],
    route: 'dev-import',
    action: 'read',
    subject: 'dev'
  },
  {
    title: 'Export',
    fa: ['fas', 'file-export'],
    route: 'dev-export',
    action: 'read',
    subject: 'dev'
  },

]
