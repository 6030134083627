<template>
  <b-row v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="content-header mb-1 d-print-none">

    <!-- Content Left -->
    <b-col class="content-header-left">
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <portal-target name="page-breadcrumbs" class="breadcrumb-wrapper">
            <b-breadcrumb v-if="$route.meta.breadcrumb">
              <b-breadcrumb-item to="/">
                <feather-icon icon="HomeIcon" size="16" class="align-text-top" />
              </b-breadcrumb-item>
              <b-breadcrumb-item v-for="item in $store.state.breadcrumb.breadcrumbs" :key="item.text" :active="item.active" :to="item.to">
                {{ item.text }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </portal-target>
        </b-col>
      </b-row>
    </b-col>

    <!-- Content Right -->
    <b-col class="content-header-right" cols="auto">
      <portal-target name="page-options"></portal-target>
    </b-col>

  </b-row>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
  watch: {
    '$route.meta': {
      handler(meta) {
        this.$store.commit('breadcrumb/UPDATE_TITLE', meta.pageTitle)
        this.$store.commit('breadcrumb/UPDATE_BREADCRUMBS', meta.breadcrumb)
        this.$store.commit('breadcrumb/UPDATE_MENU', null)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style scoped>
  [dir] .breadcrumb {
    padding: 0.3rem 0.25rem;
  }
</style>
