<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ currentUser.username || 'Annon' }}
        </p>
      </div>
      <b-avatar v-if="currentUser.avatar"
                :src="currentUser.avatar.src"
                :alt="currentUser.avatar.alt"
                size="40"
                variant="light-primary"
                badge
                class="badge-minimal"
                badge-variant="success">
        <feather-icon v-if="!currentUser.username" icon="UserIcon" size="22"/>
      </b-avatar>
      <b-avatar v-else
                size="40"
                variant="light-primary"
                badge
                class="badge-minimal"
                badge-variant="success">
        <feather-icon v-if="!currentUser.username" icon="UserIcon" size="22"/>
      </b-avatar>
    </template>

    <b-dropdown-item :to="{ name: 'account-settings' }" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="SettingsIcon" class="mr-50"/>
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item :to="{ name: 'support'}" link-class="d-flex align-items-center">
      <feather-icon size="16" icon="HelpCircleIcon" class="mr-50" />
      <span>Support</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
      <feather-icon size="16" icon="LogOutIcon" class="mr-50"/>
      <span>Logout</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {Auth} from 'aws-amplify';
import store from '@/store';

export default {
  computed: {
    currentUser() {
      return this.$store.state.account
    }
  },
  methods: {
    async logout() {
      try {
        await Auth.signOut().then(async () => {
          await sessionStorage.clear()
          await store.dispatch('all-state-applications/clear')
          await store.dispatch('all-state-scores/clear')
          await store.dispatch('account/logout').then(() => {
            this.$router.push({name: 'auth-login'})
          })
          await store.dispatch('settings/clear')
        })
      }
      catch (error) {
        console.error(error)
      }
    }
  },
}
</script>
